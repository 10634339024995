import * as React from "react"
import styled from "styled-components";


import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/Title';
import Image from '../components/Image';

const Box = styled.div`
  @media(max-width: 1000px){
    padding: 0 20px;
  }
`;

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  flex-wrap: wrap;

  p{
    margin-right:20px;
  }
  h4 {
    margin-left: 10px;
  }
  .image-icon{
    height: 31px;
    width: 31px;
  }
  .image-aseo{
    height: 232px;
    width: 242px;
  }
  .featurette-SubTitulo {
    font-size: 24px;
  }

  @media(max-width: 1000px){
    flex-direction: column;
    align-items: center;

    img{
      margin-top:20px;
      width:80%;
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Aseo Industrial" />
    <Box>
      <Title title='Aseo' secondTitle='Industrial' />
  
      <Container>
        <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Supermercados salas de procesos" />
        <h4 class="featurette-SubTitulo">Supermercados salas de procesos</h4>
      </Container>
      

      <Container>
        <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Limpieza de hospitales y clínicas" />
        <h4 class="featurette-SubTitulo">Limpieza de hospitales y clínicas</h4>
      </Container>
      

      <Container>
        <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Hoteles y Aeropuertos" />
        <h4 class="featurette-SubTitulo">Hoteles y Aeropuertos</h4>
      </Container>
      

      <Container>
        <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Empresas de transporte" />
        <h4 class="featurette-SubTitulo">Empresas de transporte</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Restaurantes y centros comerciales" />
        <h4 class="featurette-SubTitulo">Restaurantes y centros comerciales</h4>
      </Container>
      

      <Container>
        <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Instituciones educativas" />
        <h4 class="featurette-SubTitulo">Instituciones educativas</h4>
      </Container>
      

      <Container>
        <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Fábricas industriales" />
        <h4 class="featurette-SubTitulo">Fábricas industriales</h4>
      </Container>
      

      <Container>
        <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Centros de veterinaria" />
        <h4 class="featurette-SubTitulo">Centros de veterinaria</h4>
      </Container>
      
      <p><br />Nuestro principal objetivo con el cliente, es la eficiencia y la garantía de un trabajo de limpieza profunda, desalinización, desbaste, desgrase y desincrustación bien realizado! Solucionamos todos sus inconvenientes de mantenimiento, limpieza industrial e higiene en sectores de salas de proceso de carnicerias, fiambrerias, pastelerias , casinos, sanitizacion de mesas y sillas , barandas, higiene urbana, transporte de pasajeros, contaminación de químicos , entre otros. 
      <br />Ponemos a su disposición el novedoso sistema de maquinas industriales de vapor continuo a 130 ° C , que permite la descontaminación total de las zonas intervenidas asi como de contenedores de traslado , camiones de transporte etc Nuestras máquinas de vapor, aspiradoras industriales, hidrolavadora, son ideales para limpiar CUALQUIER TIPO DE SUPERFICIE
      </p>
    



      <Container style={{ justifyContent: 'center' }}>
        <Image className="image-aseo" srcName="AseoInd_6.png"  alt="Aseo Industrial" />
        <Image className="image-aseo" srcName="AseoInd_1.png" width="242" height="232" alt="Aseo Industrial" />
        <Image className="image-aseo" srcName="AseoInd_2.png" width="242" height="232" alt="Aseo Industrial" />
        <Image className="image-aseo" srcName="AseoInd_3.png" width="242" height="232" alt="Aseo Industrial" />
        <Image className="image-aseo" srcName="AseoInd_4.png" width="242" height="232" alt="Aseo Industrial" />
        <Image className="image-aseo" srcName="AseoInd_5.png" width="242" height="232" alt="Aseo Industrial" />
        <Image className="image-aseo" srcName="AseoInd_6.png" width="242" height="232" alt="Aseo Industrial" />
        <Image className="image-aseo" srcName="AseoInd_7.png" width="242" height="232" alt="Aseo Industrial" />
      </Container>
    </Box>
  </Layout>
)

export default IndexPage
    
  